.asendusauto-page {
    margin-top: 97px; /* For the navbar */
    background-color: var(--color-bg);
    font-family: var(--font-family);
    justify-content: center;
}

.asendusauto-title {
    font-size: 50px;
    text-align: left;
    margin: 0 0 2rem 0;
    padding: 0;
    background: linear-gradient(to right, #C81E28, #ff8282);
    -webkit-background-clip: text;
    border-radius: 25px;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    line-height: 1.2;
}

.asendusauto-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
    padding: 5rem 0 0 0;
    justify-content: center;
}

.asendusauto-image {
    width: 800px;
    height: auto;
    margin-left: 50px;
    border-radius: 25px;
}

.asendusauto-item {
    display: flex;
    width: 100%;
    margin-bottom: 5rem;
    align-items: center;
}
.asendusauto-text {
    text-align: justify;
}

@media screen and (max-width: 1480px) {
    .asendusauto-image {
        width: 600px;
    }
}

@media screen and (max-width: 1350px) {
    .asendusauto-title {
        font-size: 40px;
    }
}

@media screen and (max-width: 1290px) {

    .asendusauto-text {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 1180px) {
    .asendusauto-image {
        width: 500px;
    }
}

@media screen and (max-width: 1050px) {
    
    .asendusauto-title {
        text-align: center;
    }

    .asendusauto-item {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    
    .asendusauto-content {
        margin-bottom: 5rem;
    }
    
    .asendusauto-image {
        margin-left: 0;
    }
}

@media screen and (max-width: 600px) {
    
    .asendusauto-image {
        border-radius: 0;
        width: 100vw;
        
    }

    .asendusauto-item {
        margin-bottom: 0;
    }
}