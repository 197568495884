.teenused {
    background-color: black;

    color: white;
    height: auto;
    font-family: var(--font-family);
    padding: 6rem 0rem;
}

.teenused h1 {
    font-size: 60px;
    line-height: 0;
}

.teenused-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-right: 16rem;
    margin-top: 4rem;
}

.teenused-column1 {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: top;    
}

.teenused-column2 {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: top;    
}

/*.teenused-container .default-button {*/
/*    width: 50%;*/
/*    font-size: 150%;*/
/*}*/

.teenused-object 
{
    margin: 1rem 0;
    display: flex;
}

.teenused-object .teenused-line {
    margin: auto 0;
}
.teenused-object h3 {
    font-size: 40px;
    margin: auto 1rem;
}

@media screen and (max-width: 885px) {
    .teenused h1 {
        font-size: 50px;
    }

    .teenused-object h3 {
        font-size: 30px;
    }
}

@media screen and (max-width: 760px) {
    .teenused h1 {
        font-size: 40px;
    }

    .teenused-object h3 {
        font-size: 25px;
    }
}

@media screen and (max-width: 685px) {

    .teenused {
        text-align: center;
    }

    .teenused-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 3rem 0 0 0;
        grid-gap: 0;
    }
    .teenused h1 {
        font-size: 60px;
    }

    .teenused-object {
        justify-content: left;
    }

    .teenused-object h3 {
        font-size: 30px;
    }
}

@media screen and (max-width: 400px) {

    .teenused h1 {
        font-size: 50px;
    }

    .teenused-object {
        justify-content: left;
    }

    .teenused-object h3 {
        font-size: 20px;
    }
}
