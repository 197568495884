.teenused-page {
    margin-top: 97px; /* For the navbar */
    background-color: var(--color-bg);
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
}

.show-mobile 
{
    display: none;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 75rem;
    padding: 5rem 0 0 0;
    justify-content: center;
    
  }

  .services-title {
    font-size: 50px;
    text-align: center;
    margin-bottom: 5rem;
  }
  
  .service-item {
    background: linear-gradient(to right, #d6d5d5, #ffffff);
    display: flex;
    width: 100%;
    margin-bottom: 5rem;

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    border-radius: 25px;
  }

  .service-item.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  .service-item:hover {
    /*background-color: #f4f4f4;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s, box-shadow 0.3s; */
    border-radius: 25px;

  }

  .service-item-right {
    background: linear-gradient(to left, #d6d5d5, #ffffff);
  }
  
  .service-content {
    flex: 1;
    margin: auto;
  }
  
  .service-title {
    text-align: left;
    background: linear-gradient(to right, #C81E28, #ff8282);
    -webkit-background-clip: text;
    color: transparent;
    margin-left: 40px;
    margin-bottom: 8px;
  }

  .service-text {
    text-align: justify;
    margin-left: 40px;
  }

  .service-content-right
  {
    margin-left: 0px;
    margin-right: 40px;
  }
  
  
  .service-image {
    width: 500px;
    height: auto;
    margin-right: 40px;
    border-radius: 25px 0 0 25px;
  }

  .service-image-right
  {
    width: 500px;
    height: auto;
    margin-left: 40px;
    border-radius: 0 25px 25px 0;
  }

  @media screen and (max-width: 1050px) {
    .hide-mobile {
        display: none;
    }

    .show-mobile 
    {
        display:block;
    }

    .services-container {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 5rem 0 0 0;
        justify-content: center;
        
      }
    .service-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--color-bg);
    }

    .service-content,
    .service-content-right
    {
        text-align: center;
    }

    .service-title{
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }

    .service-text {
        text-align: center;
        margin: 0;
    }

    .service-image
    {
        margin: 2rem 0 0 0;
        border-radius: 25px;
    }

    .service-image-right
    {
        margin: 2rem 0 0 0;
        border-radius: 25px;
    }
}

@media screen and (max-width: 1050px) {

    .service-image,
    .service-image-right {
        width: 100%;
    }

    .services-container {
        padding: 3rem 0 0 0;
    }

    .services-title{
        margin-bottom: 3rem;
    }
}