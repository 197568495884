.kontakt_page_bg {
    background-color: white;
    margin-top: 97px; /* For the navbar */
}

.kontakt_page {
    font-family: var(--font-family);
}

.aadress {
    margin: 3rem 0;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.aadress p,
.kontakt-info p {
    font-size: 20px;
    display: inline-block;
}

.black-line {
    height: 1px;
    width: 100%;
    background-color: black;
}

.kontakt-info {
    margin: 2rem 0;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
}

.telephone-number h1,
.email-address h1,
.google-maps h1 {
    display: inline-block;
    transition: color 0.3s ease, color 0.3s ease;
}

.telephone-number h1:hover,
.email-address h1:hover,
.google-maps h1:hover  {
    cursor: pointer;
    color: var(--color-red);
}

.kontakt-info h1 {
    font-weight: 400;
}

@media screen and (max-width: 1050px) {

    .heading-column p {
        font-size: 17px;
    }
    .content-column {
        font-size: 14px;
    }
}

@media screen and (max-width: 950px) {

    .kontakt-info {
        grid-template-columns: 2fr 4fr 2fr;
    }

    .content-column {
        text-align: right;
    }
}

@media screen and (max-width: 780px) {

    .kontakt-page,
    .aadress,
    .kontakt-info {
        display: flex;
        flex-direction: column;
    }

    .content-column {
        text-align: center;
    }

    .kontakt-info p {
        margin: 0 0 0.7rem 0;
    }

    .content-column h1 {
        margin: 0.3rem 0;
    }
}

@media screen and (max-width: 370px) {

    .heading-column p {
        font-size: 16px;
        font-weight: 500;
    }

    .content-column h1 {
        font-size: 20px;
    }
}