.header {
    background-image: url(../../assets/automaaler-design1.png);
    background-size: cover;
    background-position: center;

    height: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 97px; /* For the navbar */
}

.header-info {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.header-title {
    margin: 0;
    width: 70%;
    text-align: left;
    font-family: var(--font-family);
}

.header-title h1 {
    font-size: 70px;
    color:white;
}

.header-logo 
{
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: column;
}

.header-logo img {
    display: none;
    margin: 0 0 20rem 0;
    height: calc(6426px * 0.06);
    width: calc(2775px * 0.2);
}

  @media screen and (max-width: 1650px) {
    .header {
        background-image: url(../../assets/automaaler-design1_narrow1.png);
    }
}

@media screen and (max-width: 1350px) {
    .header {
        height: 700px;
        background-image: url(../../assets/automaaler-design1_narrow2.png);
    }
}

@media screen and (max-width: 1050px) {
  .header-title h1 {
    font-size: 55px;
  }

  .header-title .default-button {
    font-size: 24px;
  }
}

@media screen and (max-width: 980px) {
  .header {
    background-image: url(../../assets/automaaler-design1_narrow3.png);
}
  .header-title h1 {
    font-size: 48px;
  }
}


@media screen and (max-width: 900px) {
    .header {
        height: 650px;
        background-image: url(../../assets/meekarg_rotated_cropped2.jpg);
    }

    .header-title h1 {
      font-size: 55px;
    }

    .header {
      grid-template-columns: 1fr;
    }
    
    .header-info {
      flex-direction: unset;
      text-align: center;
      margin:auto 0;
    }
    
    .header-title {
      text-align: center;
      justify-content: center;
    }
}

@media screen and (max-width: 750px) {
  .header {
      height: 650px;
      background-image: url(../../assets/meekarg_rotated_cropped2.jpg);
  }

  .header-title h1 {
    font-size: 55px;
  }
}

@media screen and (max-width: 550px) {

  .header {
    height: 500px;
  }

  
  .header-title {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {

  .header-title h1{
   font-size: 40px;
  }
}
  
  


  