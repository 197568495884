.asendusauto {
    display: grid;
    grid-template-columns: 2fr 3fr;
    font-family: var(--font-family);
    background-color: white;
}

.asendusauto-column1 {
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
}

.asendusauto-column1 h1{
    color: var(--color-red);
    margin: 0 0 1rem 0;
}

.asendusauto-column1 p {
    font-size: 17px;
}

.asendusauto-column2 img {
    width: 100%;
    height: 100%;
}

/*.asendusauto-column1 .default-button {*/
/*    width: 70%;*/
/*    font-size: 30px;*/
/*}*/

@media screen and (max-width: 1360px) {
    #default-button {
        font-size: 20px;
        width: 100%;
        padding: 13px 0;
    }

}

@media screen and (max-width: 1250px) {

    .asendusauto-column1 h1 {
        font-size: 28px;
    }

    .asendusauto-column1 p {
        font-size: 14px;
    }

    .content_container .default-button {
        font-size: 18px;
    }

}

@media screen and (max-width: 1050px) {

    .asendusauto-column1 h1 {
        font-size: 28px;
        margin-bottom: 0.5rem;
    }

    .asendusauto-column1 p {
        font-size: 13px;
    }

    .content_container .default-button {
        font-size: 18px;
        width: 85%;
        margin-top: 1rem;
    }

    .asendusauto-column2 {
        margin-bottom: -5px;
    }
}

@media screen and (max-width: 900px) {
    .asendusauto {
        display: flex;
        flex-direction: column;
    }

    .asendusauto-column1 {
        margin: 3rem 0;
    }

    .asendusauto-column1 .default-button {
        width: 40%;
    }
    #default-button {
        width: 70%;
    }

}

@media screen and (max-width: 900px) {
    #default-button {
        width: 100%;
    }

}

@media screen and (max-width: 685px) {
    #default-button {
        width: 88%;
    }
    .asendusauto {
        justify-content: center;
        text-align: center;
    }

}

@media screen and (max-width: 450px) {
    
    .asendusauto-column1 .default-button {
        font-size: 15px;
        width: 100%;
    }

    #default-button {
        font-size: 16px;
    }

}

@media screen and (max-width: 330px) {

    .asendusauto h1 {
        font-size: 30px;
    }
}