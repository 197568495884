.map {
    width: 100%;
    height: 600px;
    margin-bottom: -4px;
    padding: 0;
}

@media screen and (max-width: 885px) {
    .map {
        height: 450px;
    }
}