.footer {
    color: white;
    font-family: var(--font-family);
}

.footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: flex-start;
    padding: 6.5rem 0;
}

.footer-logo {
    margin: auto 0;
}

.footer-column {
    position: relative;
}

.footer-column img {
    width: 360px;
    height: 165px;
    cursor: pointer;
}

.footer-object {
    margin: 1rem 0;
    display: flex;
}

.footer-icon {
    margin: auto 0;
}

.footer-object p {
    font-size: 20px;
    margin: auto 1rem;
}

.bottom-line {
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: white;
    bottom: -1rem;
}

.telephone-number p,
.email-address p,
.google-maps p,
#footer-facebook {
    transition: color 0.3s ease, color 0.3s ease;
}

.telephone-number p:hover,
.email-address p:hover,
.google-maps p:hover  {
    cursor: pointer;
    color: var(--color-red);
}

.footer-facebook:hover {
    color: var(--color-blue);
}
@media screen and (max-width: 1450px) {

    .footer-column img {
        width: 320px;
        height: 145px;
    }
}

@media screen and (max-width: 1350px) {

    .footer-column img {
        width: 300px;
        height: 135px;
    }

    .footer-column {
        padding-right: 1rem;
    }

    .footer-column h1 {
        font-size: 28px;
    }
    .footer-object p {
        font-size: 16px;
    }
}

@media screen and (max-width: 1200px) {
    .footer-column img {
        width: 280px;
        height: 125px;
    }
}

@media screen and (max-width: 1050px) {

    .footer-column img {
        width: 320px;
        height: 145px;
        margin-top: -2rem;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
    }

    .footer-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        padding-right: 0;
    }

    .footer-object {
        align-items: center;
    }

    .footer-column h1 {
        font-size: 36px;
    }

    .footer-object p {
        font-size: 20px;
    }

    .bottom-line {
        display: none;
    }
}

@media screen and (max-width: 500px) {

    .footer-icon {
        width: 25px;
    }

    .footer-column h1 {
        font-size: 28px;
    }

    .footer-object p {
        font-size: 16px;
    }
}

@media screen and (max-width: 400px) {
    .footer-column h1 {
        font-size: 24px;
    }

    .footer-object p {
        font-size: 14px;
    }

    .footer-object {
        margin: 0.5rem 0;
    }
}