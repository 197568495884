.gallery_bg {
  background: var(--color-bg);
  margin-top: 97px; /* For the navbar */
}

.gallery-text {
  font-family: var(--font-family);
  text-align: center;
  padding: 4rem 0;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 0 0 6rem 0;
}

.image-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.image-container img {
  width: 350px;
  height: auto;
  transition: transform 0.6s ease;
  vertical-align: top;
}

.image-container img:hover {
  transform: scale(1.3);
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling when modal is open */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content {
  position: relative;
  width: 80%;
  height: auto;
  max-width: 720px;
  max-height: 80vh;
  margin: auto; 
  z-index: 1000;
}

.modal-content img {
  width: 100%;
  height: auto;
  /*max-height: 80vh;*/
  object-fit: contain; /* Maintain aspect ratio */
}

.close-btn,
.prev-btn,
.next-btn {
  position: absolute;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.close-btn {
  top: 20px;
  right: 26px;
  font-size: 32px;
}

.prev-btn {
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.next-btn {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media screen and (max-width: 750px) {

  .modal-content {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {

  .image-container img {
    width: 100%;
  }
}
