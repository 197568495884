.whole-navbar {
    background-color: var(--color-black);
    width: 100%;
    max-width: 100vw;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 100vw;
    padding: 1.2rem 0rem;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links_logo {
    margin-right: 2rem;
}

.navbar-links_logo img {
    width: 120px;
    height: 55px;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navbar-links_container p,
.navbar-menu_container p {
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}
.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu_container {
    display: flex;
    text-align: center;
    justify-content: center;

    background: var(--color-black);
    position: fixed;
    top: 80px;
    left: 0;
    transform: translate(-100%, -100%);
    padding: 2rem 0 4rem 0;

    width: 100vw;
    z-index: 999;
}

.navbar-menu_container-links {
    display: flex;
    flex-direction: column;
}

.navbar-menu_container p {
    font-weight: 600;
    font-size: 32px;
    margin: 1.3rem 0;
    transition: color 0.3s ease;
    display: inline-block;
}

.navbar-menu_container p:hover {
    color: var(--color-red);
}

@media screen and (max-width: 1050px) {
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }
}