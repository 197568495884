.partnerid {
    background-image: url(../../assets/white-background1.jpg);
    margin-top: 97px; /* For the navbar */
}

.partnerid-page {
    padding: 6rem 0;
    font-family: var(--font-family);
    margin: 0 auto;
}

.partnerid-page h1 {
    font-size: 36px;
    margin: 0 auto 3rem auto;
}

.partnerid-container {
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.partner-link {
    display: block;
    text-decoration: none;
    justify-content: center;
    margin: 0 auto;
}
  
.partner-logo {
    width: 100%;
    max-width: 300px; /* Adjust the maximum width as needed */
    height: auto;
    transition: 0.3s ease;
}

.partner-logo:hover {
    scale: 1.25;
}

@media screen and (max-width: 1650px) {
    .partnerid-container {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

@media screen and (max-width: 1450px) {
    .partnerid-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media screen and (max-width: 850px) {

    .partnerid-page {
        justify-content: center;
    }

    .partnerid-page h1{
        text-align: center;
    }

    .partnerid-title {
        text-align: center;
    }

    .partnerid-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}